
import React from "react";

// reactstrap components
import {
  Badge,
  Card,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";
import spr from "./assets/img/theme/Shradhdha-Parsana.pdf";
import icp from "./assets/img/theme/ICP-Certification.pdf";
import csm from "./assets/img/theme/csm.pdf";
import kmp1 from "./assets/img/theme/kmp1.pdf";
import tkp from "./assets/img/theme/tkp.pdf";
import cspo from "./assets/img/theme/cspo.pdf";
class App extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        {/* <DemoNavbar /> */}
        <main >
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped py-lg">
              <div className="shape shape-style-1 shape-dark">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <Container className="py-lg">
                <Row className="text-center justify-content-center">
                  <Col lg="10">
                    <h2 className="display-3 text-white ">Shradhdha Parsana{" "}
                      <span>Full-stack Developer</span></h2>
                    <p className="lead text-white">
                      With over 8 years in the tech industry, I’ve solidified my stance as a prominent Full Stack Developer. My accomplishments range from launching an AI start-up in record time to spearheading a dynamic e-commerce platform for 10,000+ users. Proficient in Python, React, Node.js, and AWS, I've excelled in areas from serverless transformations to robust CI/CD pipelines. My expertise isn't just limited to development; I've overseen projects like migrating databases with zero data loss. Committed to innovation, I harness the power of technology to drive impactful results.
                    </p>
                    <div className="mt-3">
                      <a
                        className="btn btn-icon-only btn-secondary rounded-circle"
                        href="https://www.linkedin.com/in/shradhdha03"
                        target="_blank"
                      >
                        <i className="fa fa-linkedin" />
                      </a>
                      <a
                        className="btn btn-icon-only btn-secondary rounded-circle ml-1"
                        href="https://github.com/Shradhdha03"
                        target="_blank"
                      >
                        <i className="fa fa-github" />
                      </a><br />
                      <a
                        className="btn btn-secondary rounded ml-1 mt-4"
                        href={spr}
                        target="_blank"
                      >
                        Download Resume
                      </a>
                    </div>
                  </Col>
                </Row>

              </Container>


            </section>
            {/* 1st Hero Variation */}
          </div>

          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12" className="px-lg">
                  <Row className="row-grid ">
                    <Col lg="12" className="justify-content-center text-center mb-lg" id="projects">
                      <h2 className="display-3 text-uppercase">Skills</h2>
                    </Col>
                    <Col className="justify-content-center text-center mx-auto">

                      {this.getSkills(["JavaScript",
                        "Python",
                        "JavaScript",
                        "PHP",
                        "Java",
                        "Flask",
                        "React.js",
                        "Node.js",
                        "Express",
                        "Bootstrap",
                        "HTML",
                        "CSS",
                        "Angular",
                        "MongoDB",
                        "MySQL",
                        "Redis",
                        "ERPNext",
                        "WordPress",
                        "Moodle",
                        "REST API",
                        "MVC",
                        "Cypress",
                        "Mocha",
                        "Sinon",
                        "Test Driven Development",
                        "AWS",
                        "Docker",
                        "Serverless",
                        "CI CD Pineline",
                        "Lambda",
                        "EFS",
                        "Agile",
                        "Scrum",
                        "Kanban",
                        "Pair Programming",
                        "Github",
                        "LangChain",
                        "OpenAI",
                        "Cohere"
                      ])}
                    </Col>

                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg bg-gradient-default">
            {/* <Container> */}
            <Row className="justify-content-center">
              <Col lg="12" className="px-lg">
                <Row className="row-grid ">
                  <Col lg="12" className="justify-content-center text-center mb-lg" id="projects">
                    <h2 className="display-3 text-uppercase text-white">Projects</h2>
                  </Col>
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0 mb-5">
                      <CardBody className="py-5">
                        <h5 className="text-default text-uppercase">
                          <b>Berteig Website</b>
                        </h5>
                        <h6 className="description mt-3">
                          Actions:<br />
                          Responsible for the development and maintenance of the website<br />
                          Integrated it with a custom e-commerce system<br />
                          Migrated it to serverless infrastructure<br />
                          Created CI/CD pipeline for quick deployments
                        </h6>
                        <h6 className="description my-3 text-default">
                          <b>Results: Made it easy to maintain and deploy, and more scalable and secure</b>
                        </h6>
                        {this.getTechnologiesList(["WordPress", "PHP", "MySQL", "HTML", "CSS", "LESS", "AWS", "ECS", "EFS", "Docker", "CI/CD"], 'wpwebbp')}
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0 mb-5">
                      <CardBody className="py-5">
                        <h5 className="text-default text-uppercase">
                          <b>Ecommerce System</b>
                        </h5>
                        <h6 className="description mt-3">
                          Actions:<br />
                          Responsible for full-task development and maintenance<br />
                          Integrated it with many internal tools<br />
                          Created CI/CD pipeline for quick deployments
                        </h6>
                        <h6 className="description my-3 text-default">
                          <b>Results: Made it easy to deploy and maintain, enabling us to retire old system</b>
                        </h6>
                        {this.getTechnologiesList(["Node.js", "Express", "React.js", "MongoDB", "Bootstrap", "HTML", "CSS", "AWS", "CI/CD"], 'wpwebecoom')}
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0 mb-5">
                      <CardBody className="py-5">
                        <h5 className="text-default text-uppercase">
                          <b>Survey Application</b>
                        </h5>
                        <h6 className="description mt-3">
                          Actions:<br />
                          Responsible for full-stack development of the application<br />
                          Created Rest API using Node.js and Express<br />
                          Migrated data from the old system to a new system<br />
                          Integrated it with the company's internal tools
                        </h6>
                        <h6 className="description my-3 text-default">
                          <b>Results: Designed it to be simple and more user friendly</b>
                        </h6>
                        {this.getTechnologiesList(["Node.js", "Express", "React.js", "MongoDB", "Bootstrap", "HTML", "CSS", "AWS"], 'wpwebbpsurvey')}
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0 mb-5">
                      <CardBody className="py-5">
                        <h5 className="text-default text-uppercase">
                          <b>Attandance Application</b>
                        </h5>
                        <h6 className="description mt-3">
                          Actions:<br />
                          Responsible for full-stack development of the application<br />
                          Created Rest API using Node.js and Express<br />
                          Integrated it with marketing tools
                        </h6>
                        <h6 className="description my-3 text-default">
                          <b>Results: Designed it to be simple and more user friendly</b>
                        </h6>
                        {this.getTechnologiesList(["Node.js", "Express", "React.js", "MongoDB", "Bootstrap", "HTML", "CSS", "AWS"], 'appatt')}
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0 mb-5">
                      <CardBody className="py-5">
                        <h5 className="text-default text-uppercase">
                          <b>Learning Management System</b>
                        </h5>
                        <h6 className="description mt-3">
                          Actions:<br />
                          Configured E-learning System<br />
                          Responsible for regular maintenance and update<br />
                          Integrated it with internal tools
                        </h6>
                        <h6 className="description my-3 text-default">
                          <b>Results: Enabled us to offer virtual training in the Covid pandemic</b>
                        </h6>
                        {this.getTechnologiesList(["Moodle", "PHP", "MySQL", "HTML", "CSS", "AWS"], 'wpwebbpmoodle')}
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0 mb-5">
                      <CardBody className="py-5">
                        <h5 className="text-default text-uppercase">
                          <b>File Sharing System</b>
                        </h5>
                        <h6 className="description mt-3">
                          Actions:<br />
                          Responsible for configuration and maintenance<br />
                          Integrated it with internal tools
                        </h6>
                        <h6 className="description my-3 text-default">
                          <b>Results: Created reliable file storage for internal use</b>
                        </h6>
                        {this.getTechnologiesList(["PHP", "MySQL", "AWS"], 'wpwebbpown')}
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0 mb-5">
                      <CardBody className="py-5">
                        <h5 className="text-default text-uppercase">
                          <b>EquityPandit</b>
                        </h5>
                        <h6 className="description mt-3">
                          Actions:<br />
                          Converted PSD design into HTML webpages<br />
                          Developed the theme using HTML, CSS, Bootstrap, and Javascript<br />
                          Created WordPress theme including plugins, custom posts, fields, shortcodes, and widgets.<br />
                          Developed Android and iOS applications
                        </h6>
                        <h6 className="description my-3 text-default">
                          <b>Results: Created a responsive website on a tight deadline</b>
                        </h6>
                        {this.getTechnologiesList(["PHP", "MySQL", "WordPress", "HTML", "CSS", "JavaScript", "jQuery", "Android", "iOS"], 'equi')}
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0 mb-5">
                      <CardBody className="py-5">
                        <h5 className="text-default text-uppercase">
                          <b>ScoreUP</b>
                        </h5>
                        <h6 className="description mt-3">
                          Actions:<br />
                          Developed MCQ test portal for students using PHP and MySQL and responsive website with Bootstrap<br />
                          Developed the theme using HTML, CSS, Bootstrap, Javascript<br />
                          Developed Android application
                        </h6>
                        <h6 className="description my-3 text-default">
                          <b>Results: Created an easy-to-use learning portal</b>
                        </h6>
                        {this.getTechnologiesList(["PHP", "MySQL", "WordPress", "HTML", "CSS", "JavaScript", "jQuery"], 'equi')}
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0 mb-5">
                      <CardBody className="py-5">
                        <h5 className="text-default text-uppercase">
                          <b>R. K. Infratel / AirLink</b>
                        </h5>
                        <h6 className="description mt-3">
                          Actions:<br />
                          Setup ERPNext system<br />
                          Developed custom workflow using Python and JavaScript<br />
                          Performed server upgrades and maintenance
                        </h6>
                        <h6 className="description my-3 text-default">
                          <b>Results: Enabled us to serve customers quickly</b>
                        </h6>
                        {this.getTechnologiesList(["PHP", "MySQL", "WordPress", "HTML", "CSS", "JavaScript", "jQuery"], 'equi')}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* </Container> */}
          </section>


          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12" className="px-lg">
                  <Row className="row-grid ">
                    <Col lg="12" className="justify-content-center text-center mb-lg" id="projects">
                      <h2 className="display-3 text-uppercase">Experience</h2>
                    </Col>
                    <Col lg="12">
                      <Card className="card shadow border-0 mb-5">
                        <CardBody className="py-5">
                          <p className="text-default mb-0">2018 - current</p>
                          <h5 className="text-default">
                            <span className="text-default text-uppercase"><b>Full Stack Developer</b></span>
                            <span> at Berteig Consulting Inc.</span>
                          </h5>
                          <h6 className="description">

                            <ul><li>Launched AI Start-Up in 3 months, gaining paid user base shortly after.</li><li>Led full-stack development for the ecommerce platform, serving 10,000+ customers. Built a robust REST API and integrated internal tools.</li><li>Migrated WordPress site to serverless infrastructure, boosting scalability, ease of maintenance, and zero-downtime upgrades.</li><li>Applied Agile Engineering Practices (test-driven development, refactoring, pair programming, continuous integration), ensuring high-quality software.</li><li>Co-created and delivered a 2-day Agile Developer course with a 5-star rating.</li><li>Designed and implemented CI/CD pipelines for automated deployments.</li><li>Developed and managed 7 applications, including Attendance, Survey, Learning Management, and File Sharing systems.</li><li>Successfully executed a data migration from MySQL to MongoDB, retiring the old system with zero data loss.</li></ul>


                          </h6>
                          <p className="mb-0">Technologies used:</p>
                          {this.getTechnologiesList(["Python", "Flask", "LangChain", "Node.js", "React", "MongoDB", "Redis", "Cypress", "PHP", "WordPress", "Moodle", "AWS", "Docker", "CI CD Pipeline", "ECS", "EFS", "Lambda", "Serverless", "OpenAI", "cohere.ai"], 'bert')}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="12">
                      <Card className="card shadow border-0 mb-5">
                        <CardBody className="py-5">
                          <p className="text-default mb-0">2016 - 2017</p>
                          <h5 className="text-default">
                            <span className="text-default text-uppercase"><b>Software Developer</b></span>
                            <span> at R. K. Infratel</span>
                          </h5>

                          <h6 className="description ">
                            <ul><li>Developed 12 ERPNext modules for Sales, HR, CRM, and Procurement, digitizing complex processes.</li><li>Enhanced sales and procurement transparency, enabling real-time tracking of leads, customers, and inventory.</li><li>Enabled cost analysis for optimized service delivery to individual customers.</li><li>Produced complex reports for informed decision-making and analysis.</li></ul>
                          </h6>
                          <p className="mb-0">Technologies used:</p>
                          {this.getTechnologiesList(["Python", "MariaDB", "ERPNext", "JavaScript", "HTML", "CSS"], 'rk')}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="12">
                      <Card className="card shadow border-0 mb-5">
                        <CardBody className="py-5">
                          <p className="text-default mb-0">2014 - 2016</p>
                          <h5 className="text-default">
                            <span className="text-default text-uppercase"><b>Software Developer</b></span>
                            <span> at Yieldnotion</span>
                          </h5>
                          <h6 className="description ">
                            <ul><li>Built and managed 9 high-performance websites, which encompassed a file transfer application and an NFC-based order-taking application.</li><li>Developed and published 3 Android apps and 2 iOS apps.</li><li>Mentored 3 peers in responsive web development.</li><li>Maintained Google PageSpeed scores consistently above 90.</li></ul>
                          </h6>
                          <p className="mb-0">Technologies used:</p>
                          {this.getTechnologiesList(["Node.js", "Express", "Java", "Angular", "PHP", "Objective C", "Bootstrap", "MySQL", "MongoDB"], 'yiel')}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="12">
                      <Card className="card shadow border-0 mb-5">
                        <CardBody className="py-5">
                          <p className="text-default mb-0">2013 - 2014</p>
                          <h5 className="text-default">
                            <span className="text-default text-uppercase"><b>Web Developer</b></span>
                            <span> at Natham Technologies Pvt. Ltd.</span>
                          </h5>
                          <h6 className="description ">
                            <ul><li>Developed 3 high-quality WordPress websites following W3C Standards.</li><li>Transformed 15 Photoshop design files into responsive web pages, reducing load times by 10% and improving SEO rankings.</li></ul>
                          </h6>
                          <p className="mb-0">Technologies used:</p>
                          {this.getTechnologiesList(["JavaScript", "PHP", "WordPress", "Bootstrap", "HTML", "CSS", "PhotoShop", "SEO", "PageSpeed"], 'nath')}
                        </CardBody>
                      </Card>
                    </Col>

                  </Row>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="section section-lg bg-gradient-default">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12" className="px-lg">
                  <Row className="row-grid ">
                    <Col lg="12" className="justify-content-center text-center mb-lg" id="projects">
                      <h2 className="display-3 text-white text-uppercase">Education</h2>
                    </Col>
                    <Col lg="12">
                      <Card className="card shadow border-0 mb-5">
                        <CardBody className="py-5">
                          <Row className="row-grid ">
                            <Col lg="12">
                              <h6 className="text-default">2017 - 2018</h6>
                              <h5 className="text-default">
                                <span className="text-default text-uppercase"><b>Software and Information Systems Testing</b></span>
                                <span> - Graduate Certificate</span>
                              </h5>
                              <h6>
                                Fanshawe College
                              </h6>
                            </Col>
                          </Row>


                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="12">
                      <Card className="card shadow border-0 mb-5">
                        <CardBody className="py-5">
                          <Row className="row-grid ">
                            <Col lg="12">
                              <h6 className="text-default">2009 - 2013</h6>
                              <h5 className="text-default">
                                <span className="text-default text-uppercase"><b>Computer Science and Engineering</b></span>
                                <span> - Bachelor's Degree</span>
                              </h5>
                              <h6>
                                Vidyabharti Trust Institute of Technology
                              </h6>
                            </Col>
                          </Row>


                        </CardBody>
                      </Card>
                    </Col>



                  </Row>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="section section-lg">
            <Container className="pt-lg">
              <Row className="text-center justify-content-center">
                <Col lg="10">
                  <h2 className="display-3  text-uppercase">Certificates</h2>

                </Col>

              </Row>

            </Container>
            <Col lg="12" className="px-lg">
              <Row className="row-grid py-lg text-center mx-auto">
                {/* <Col lg='8'> */}
                <Col className="mb-5 mb-lg-0 text-center mx-auto" lg="2" md="3">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover bg-secondary"
                      src={require("./assets/img/theme/ICP.png")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1 ">ICAgile Certified Professional</span>
                        <small className="h6 text-muted ">(ICP) 2021</small>
                      </h5>
                      <div className="mt-3">
                        <a
                          className="btn btn-secondary rounded ml-1"
                          href={icp}
                          target="_blank"
                        >
                          View Certificate
                        </a>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col className="mb-5 mb-lg-0 text-center mx-auto" lg="2" md="3" >
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover  bg-secondary"
                      src={require("./assets/img/theme/csm.png")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1 ">Certified Scrum Master</span>
                        <small className="h6 text-muted ">(CSM) 2018</small>
                      </h5>
                      <div className="mt-3">
                        <a
                          className="btn btn-secondary rounded ml-1"
                          href={csm}
                          target="_blank"
                        >
                          View Certificate
                        </a>
                      </div>
                    </div>
                  </div>
                </Col>
                {/* <Col className="mb-5 mb-lg-0 text-center mx-auto" lg="2" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover  bg-secondary"
                      src={require("./assets/img/theme/cspo.png")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1 ">Certified Scrum Product Owner</span>
                        <small className="h6 text-muted ">(CSPO) 2020</small>
                      </h5>
                      <div className="mt-3">
                        <a
                          className="btn btn-secondary rounded ml-1"
                          href={cspo}
                          target="_blank"
                        >
                          View Certificate
                        </a>
                      </div>
                    </div>
                  </div>
                </Col> */}

                <Col className="mb-5 mb-lg-0 text-center mx-auto" lg="2" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover  bg-secondary"
                      src={require("./assets/img/theme/tkp.png")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1 ">Team Kanban Practitioner</span>
                        <small className="h6 text-muted ">(TKP) 2018</small>
                      </h5>
                      <div className="mt-3">
                        <a
                          className="btn btn-secondary rounded ml-1"
                          href={tkp}
                          target="_blank"
                        >
                          View Certificate
                        </a>
                      </div>
                    </div>
                  </div>
                </Col>

                {/* <Col className="mb-5 mb-lg-0 text-center mx-auto" lg="2" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover  bg-secondary"
                      src={require("./assets/img/theme/kmp.png")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1 ">Kanban System Design</span>
                        <small className="h6 text-muted ">(KMP I) 2021</small>
                      </h5>
                      <div className="mt-3">
                        <a
                          className="btn btn-secondary rounded ml-1"
                          href={kmp1}
                          target="_blank"
                        >
                          View Certificate
                        </a>
                      </div>
                    </div>
                  </div>
                </Col>  */}
                {/* </Col> */}
              </Row>
            </Col>
          </section>


        </main>
      </>
    );
  }

  getSkills(skills) {
    return skills.map((skill) => {
      return <a href="#skills" className="btn btn-default rounded ml-2  mb-3 text-white" key={skill + "skill"}>{skill}</a>
    });
  }

  getTechnologiesList(skills, project_name) {
    return skills.map((skill) => {
      return <Badge color="default" key={skill + project_name} className="mr-1 text-white">
        {skill}
      </Badge>
    })

  }
}

export default App;
